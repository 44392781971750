<template>
  <div class="container settings">
    <div class="row mb-3">
      <div class="col">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body text-center">
            <div class="row mb-3">
              <div class="col-md-8 offset-md-2">
                <label for>Current Email</label>
                <input
                  type="text"
                  :value="user.email"
                  class="form-control disabled text-center"
                  disabled
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-8 offset-md-2">
                <label for>New Email Address</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-center "
                    :class="verifySent ? 'disabled' : ''"
                    v-model="newEmail"
                    :disabled="verifySent"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3" v-if="verifySent">
              <div class="col-md-8 offset-md-2">
                <label for>Verification Code</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-center "
                    v-model="verifyCode"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-8 offset-md-2">
                <div class="text-center">
                  <div class="alert alert-danger" v-if="error != ''">
                    {{error}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <div class="text-center">
                  <button
                    class="btn btn-secondary mr-2"
                    type="button"
                    @click="$router.push({ name: 'SettingsDetailsView' })"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-success"
                    v-if="!verifySent"
                    @click="onVerify"
                  >
                    Verify
                  </button>
                  <button class="btn btn-primary" v-else @click="onConfirm">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      newEmail: "",
      verifySent: false,
      confirmed: false,
      error: "",
      verifyCode: "",
      verifyReal: "",
    };
  },
  computed: {
    user() {
      return this.$store.user;
    },
  },
  methods: {
    onConfirm() {
      if(this.verifyReal == this.verifyCode)
      {
        this.user.email = this.newEmail;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/client/update-user-settings",
            this.user
          )
          .then(({ data }) => {
            this.$EventBus.$emit("refreshUser");
            this.$EventBus.$emit("alert", data);
            this.$router.push({ name: 'SettingsDetailsView' });
          })
          .catch((error) => {
          this.error = "There was an error updating user settings, please retry.";
        });
      }
      else
      {
        this.error = "Your verification code was invalid, please retry.";
      }
    },
    onVerify() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/client/verify-email",
          { email: this.newEmail }
        )
        .then(({ data }) => {
          this.verifyReal = data.token;
          this.verifySent = true;
          this.errorVerify = false;
        })
        .catch((error) => {
          this.error = "There was an error sending verification, please retry.";
        });
    },
  },
  watch: {},
};
</script>

<style>
</style>
